:root {
  --bg-color: #eaf1ff;
  --bg-color2: #ff6727;
  --accent: #2662f5;
  --accent2: #2ec56a;
  --white: #fff;
  --darkaccent2: #a50f0e;
  --black: #1a1b20;
  --bs-gutter-x: 3rem;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--black);
}

body {
  font-family: "DM Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}

.h1,
.h2 {
  font-size: 3rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.75rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.25rem;
}

img {
  max-width: 100%;
}

p {
  font-size: 1rem;
  line-height: 150%;
  font-weight: 300;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.mainWrapper {
  header {
    padding: 1rem 0;
    display: none;

    @media (min-width: 992px) {
      padding: 1rem 0;
      display: none;
    }

    position: relative;
    z-index: 2;

    .logo-wrapper {
      a {
        img {
          max-width: 100%;
        }
      }
    }
  }

  .hero-section {
    padding: 5rem 0 0 0;
    position: relative;
    overflow: hidden;
    text-align: center;
    background-color: radial-gradient(
      48.6% 799.61% at 50% 50%,
      #4776e6 0%,
      rgba(142, 84, 233, 0) 100%
    );

    // &::after {
    //   content: "";
    //   background: var(--Hero-G2, linear-gradient(158deg, #536EFF -1.67%, #C6C9FF 61.19%, #23FFFF 136.79%));
    //   height: 40%;
    //   width: 100%;
    //   bottom: 0;
    //   position: absolute;
    //   left: 0;
    //   @media (min-width: 992px) {
    //     left: auto;
    //     height: 100%;
    //     right: 0;
    //     width: 40%;
    //   }
    // }
    @media (min-width: 992px) {
      text-align: left;
    }

    .container {
      padding: 0;
      position: relative;
      z-index: 2;

      @media (min-width: 992px) {
        padding: 0 0.5rem;
      }

      .row {
        min-height: 50vh;
        align-items: center;
        justify-content: center;

        //padding: 0 1rem;
        @media (min-width: 992px) {
          align-items: center;
        }

        .hero-container {
          padding: 0 1rem;
          border-radius: 8px;
          width: 100%;
          flex: 0 0 auto;

          // @media (min-width: 992px) {
          //   width: 50%;
          // }

          .sub-hero-section {
            @media (min-width: 992px) {
              display: flex;
              justify-content: space-between;
            }
            .hero-section-wrapper {
              h2 {
                font-size: 30px;
                line-height: 1.33;
                // padding: 2rem 0;
                color: var(--black);

                @media (min-width: 992px) {
                  font-size: 2.5rem;
                }
              }
            }
            .hero-section-wrapper-content {
              margin: 2rem;
              p {
                font-size: 16px;
                color: var(--black);

                @media (min-width: 992px) {
                  font-size: 18px;
                }
              }
            }
          }

          .cta {
            text-align: center;
            @media (min-width: 992px) {
              text-align: left;
              padding-top: 4rem;
              display: flex;

              // padding-left: 120px;
              // padding-right: 120px;
            }
            a {
              position: relative;
              z-index: 0;
              overflow: hidden;
              border-radius: 10px;
              padding-top: 13px;
              padding-bottom: 13px;
              padding-left: 60px;
              padding-right: 60px;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5rem;
              letter-spacing: -0.3px;
              background: #ff6727;

              color: var(--white);
              display: inline-block;

              &:hover,
              &:focus {
                background-color: var(--darkaccent2);
              }
            }

            span {
              margin-top: 0.5rem;
              display: block;
              color: var(--accent);
              font-weight: 700;

              @media (min-width: 992px) {
                margin-left: 1rem;
                text-align: center;
                padding-top: 0.5rem;
              }

              font-size: 1rem;
            }
          }
          .card-container {
            margin-top: 2rem;
            position: relative;

            @media (min-width: 992px) {
              text-align: left;
              width: 100%;
              padding: 0;
            }

            .mainbg {
              position: relative;

              p {
                max-width: 100%;
                font-size: 2rem;
                @media (min-width: 992px) {
                  font-size: 1.5rem;
                  font-weight: 300;
                }
              }
            }
          }
        }

        .question-item {
          padding: 0 1rem;
          .congrats {
            max-width: 580px;
            width: 100%;
            background-color: var(--bg-color);
            padding: 1.25rem;

            @media (min-width: 992px) {
              padding: 2rem;
            }

            border-radius: 18px;
            text-align: center;

            h1 {
              font-size: calc(1.375rem + 1.5vw);
              line-height: 1.5;

              @media (min-width: 992px) {
                font-size: 2.5rem;
              }
            }

            h2 {
              color: #0058ff;
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              line-height: 120%;
            }

            a {
              display: block;
              margin: 1rem 0;
              background: var(--bg-color2);

              color: #fff;
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              transition: all 0.2s ease-in-out;

              &:hover,
              &:focus {
                background-color: var(--darkaccent2);
              }
            }

            p {
              span {
                color: #0058ff;
                font-weight: bold;
              }
            }

            img {
              margin-top: 1rem;
            }
          }
        }
        .question-wrapper {
          flex: auto;
          width: 100%;
          background-color: var(--bg-color);
          margin: 0 1rem;
          padding: 1.5rem;
          border-radius: 10px;

          @media (min-width: 992px) {
            margin: 0;
            padding: 2.5rem;
            max-width: 600px;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--accent);
              letter-spacing: -0.3px;
              text-transform: capitalize;
              text-align: center;
            }
          }

          .question-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .question {
              position: relative;
              text-align: center;

              @media (min-width: 992px) {
                padding: 0;
                background-color: transparent;
                box-shadow: none;
                margin: 0;
              }

              border-radius: 18px;
              margin: 1rem auto;
              max-width: 580px;
              width: 100%;

              h2 {
                font-size: 25px;
                font-weight: 700;

                @media (min-width: 1200px) {
                  font-size: 2rem;
                }
              }

              a {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1rem 0;
                background: var(--bg-color2);

                color: #fff;
                font-weight: bold;
                text-decoration: none;
                padding: 0 1rem;
                font-size: 18px;
                border-radius: 8px;
                box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
                height: 50px;
              }
              .option-renderer-wrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                margin-top: 2rem;
                .options-container {
                  width: 100%;
                  input {
                    display: none;
                  }

                  .option-button {
                    width: 100%;
                    color: #fff;
                    outline: none;
                    border: none;
                    display: flex;
                    align-items: center;
                    padding: 16px 32px;
                    font-size: 18px;
                    border-radius: 4px;
                    cursor: pointer;
                    justify-content: center;
                    background: var(--bg-color2);
                    text-align: center;
                    user-select: none;
                    font-weight: bold;
                  }
                }
              }
            }
          }

          h1 {
            color: #fff;
          }
        }
      }
    }
  }

  .card-section {
    position: relative;
    z-index: 5;
    padding: 2rem 0;
    background: #f0f6fb;

    backdrop-filter: blur(15px);

    .container {
      .row {
        justify-content: center;

        .custom-container {
          width: 100%;

          .icon-pack {
            margin-top: 1rem;
            @media (min-width: 992px) {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            }
            .headline-back {
              text-align: center;
              flex: 0 0 auto;
              width: 100%;
              @media (min-width: 992px) {
                width: 30%;
              }
              h2 {
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: 2px;
                @media (min-width: 992px) {
                  font-size: 1.5rem;
                }
              }
            }

            .logo-icon-pack {
              max-width: 38.75rem;
              margin: 1rem auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              gap: 0.5rem;

              @media (min-width: 992px) {
                width: 70%;
                margin: 0;
                flex: 0 0 auto;
                max-width: 100%;
              }

              img {
                flex: 0 0 auto;
                width: 25%;
                margin-bottom: 0.25rem;

                @media (min-width: 992px) {
                  width: 15%;
                }
              }
            }
          }
        }
      }
    }
  }

  .section1 {
    padding: 3rem 0;
    background: #f0f6fb;
    @media (min-width: 992px) {
      padding: 4rem 0;
    }

    .container {
      .row {
        align-items: center;

        .section-1-content {
          flex: 0 0 auto;
          width: 100%;
          margin: 0 auto;

          @media (min-width: 992px) {
            width: 50%;
            padding: 0 1rem;
            margin: 0 auto;
            text-align: center;
          }

          h1 {
            margin-bottom: 1rem;
            line-height: 140%;
            font-size: 24px;
            letter-spacing: 0.036px;
            @media (min-width: 992px) {
              font-size: 2.5rem;
            }
          }
          p {
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0.08px;
          }

          .cta-holder {
            max-width: 580px;
            margin: 1rem 0;

            a {
              position: relative;
              z-index: 0;
              overflow: hidden;
              border-radius: 10px;
              padding-top: 13px;
              padding-bottom: 13px;
              padding-left: 40px;
              padding-right: 40px;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5rem;
              letter-spacing: -0.3px;
              background: #ff6727;
              color: var(--white);
              display: inline-block;

              &:hover,
              &:focus {
                background-color: var(--darkaccent2);
              }
            }
          }
        }

        .item-benefits {
          padding: 1rem 0;
          @media (min-width: 992px) {
            grid-template-columns: repeat(4, 1fr);
            width: 100%;
          }
          @media (max-width: 600px) {
            grid-template-columns: 1fr;
            width: 100%;
          }
          width: 85%;
          margin: auto;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 1rem;
          overflow: hidden;
          .card {
            background-color: var(--white);
            border-radius: 5px;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
            img {
              width: 100%;
              height: auto;
              border-radius: 5px 5px 0 0;
              object-fit: cover;
            }
            .card-content {
              padding: 20px;
              display: flex;
              flex-wrap: wrap;
              h2 {
                color: #ff6727;

                font-size: 1rem;
                margin-bottom: 6px;
              }
              p {
                color: var(--black);
                list-style: 1.5;
              }
            }
          }
        }
      }
    }
  }

  .section2 {
    padding: 3rem 0;

    @media (min-width: 992px) {
      padding: 5rem 0;
    }
    .container {
      .row {
        .section2-headline-container {
          width: 100%;
          text-align: center;
          @media (min-width: 992px) {
            width: 60%;
            margin: 0 auto 2rem auto;
          }
          h1 {
            margin-bottom: 1rem;
            font-size: 24px;
            font-weight: 700;
            line-height: 140%; /* 33.6px */
            letter-spacing: 0.036px;
            @media (min-width: 992px) {
              font-size: 3rem;
            }
          }

          p {
            margin-bottom: 2rem;
            font-weight: 400;
            line-height: 150%; /* 24px */
            letter-spacing: 0.08px;
          }
        }

        .section2-stat-counter {
          width: 100%;
          text-align: center;
          @media (min-width: 992px) {
            display: flex;
            gap: 1rem;
            text-align: left;
          }

          .statCounter {
            margin-bottom: 1.5rem;

            @media (min-width: 992px) {
              .statCounter {
                width: 25%;
              }
            }

            .statCounterStats {
              h3 {
                color: #ff6727;

                font-size: 24px;
              }

              span {
                font-size: 20px;
                font-weight: 600;
              }
            }
            .statCounterIntro {
              p {
                padding-top: 1rem;
              }
            }
          }
        }
      }
    }
  }
  .testimonial {
    padding: 3rem 0;
    text-align: left;
    background: #f0f6fb;

    @media (min-width: 992px) {
      padding: 4rem 0;
    }

    img {
      max-width: 100%;

      @media (min-width: 992px) {
        max-width: 580px;
      }
    }

    .testimonial-headline {
      flex: 0 0 auto;
      width: 100%;
      h1 {
        margin-bottom: 1rem;
        line-height: 120%;
        font-size: 24px;
        font-weight: 700;
        line-height: 140%; /* 33.6px */
        letter-spacing: 0.036px;
        color: var(--black);
        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }
    }

    .testimonial-content-holder {
      flex: 0 0 auto;
      width: 100%;
      @media (min-width: 992px) {
        display: flex;
        justify-content: space-between;
      }

      h3 {
        font-weight: 500;
        color: var(--black);
      }

      p {
        margin-bottom: 1rem;
        color: var(--black);
        font-weight: 600;

        @media (min-width: 992px) {
          width: 40%;
        }
      }
    }

    .testimonial-list {
      display: flex;
      margin-top: 2rem;
      flex-wrap: wrap;
      .testimonial-row {
        @media (min-width: 992px) {
          display: flex;
          justify-content: space-between;
        }
        .testimonial-item {
          margin-bottom: 1rem;
          flex: 0 0 auto;
          width: 100%;
          margin-bottom: 1.5rem;

          @media (min-width: 992px) {
            width: 50%;
            padding: 0 1rem;
          }

          .testimonial-inner {
            padding: 24px;
            text-align: left;
            border-radius: 10px;
            background-color: var(--white);

            .testimonial-top-rating {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .star-rating {
                img {
                  width: 100px;
                }
              }
              .review {
                img {
                  width: 200px;
                }
              }
            }

            .testimonial-top {
              display: flex;
              align-items: center;
              margin-top: 1rem;
              &:nth-of-type(1) {
                margin-bottom: 1rem;
              }

              .userimg {
                max-width: 38px;
                border-radius: 100%;
              }
              p {
                font-size: 0.875rem;
                margin-left: 8px;
              }
            }
            .testimonial-bottom {
              h3 {
                margin-top: 16px;
                font-size: 10px;
                color: var(--black);
                span {
                  font-size: 10px;
                  color: #505050;
                }
              }
            }
          }
        }
      }
    }
  }
  .section3 {
    padding: 3rem 0;
    align-items: center;
    background: #fafcff;

    .container {
      .row {
        display: block;
        align-items: center;
        justify-content: center;

        .faq {
          flex: 0 0 auto;
          width: 100%;
          text-align: center;

          @media (min-width: 992px) {
            width: 50%;
            padding: 0 1rem;
            margin: 0 auto;
          }

          h4 {
            font-size: 2rem;
            line-height: 50px;
            letter-spacing: 0.003em;
            margin-bottom: 8px;
          }
          p {
            color: var(--black);
            font-weight: bold;
          }
        }

        .accordion {
          margin-top: 50px;
          cursor: pointer;

          .accordion-list {
            margin-bottom: 18px;
            background-color: var(--white);
            padding: 16px 20px;
            box-sizing: border-box;
            border-radius: 8px;

            .accordion-title {
              display: flex;
              justify-content: space-between;
              .question {
                font-size: 1rem;
                font-weight: 600;
                color: #2ec56a;
              }
            }
          }

          .accordion-list.active {
            background-color: var(--accent2);
            .question {
              color: var(--black);
            }
          }
          .icon {
            margin-right: 14px;
            padding: 2px 4px;
            position: relative;
            margin-bottom: 5px 5px;
            display: inline-block;
            cursor: pointer;
            border-radius: 8px;
          }

          .answer {
            margin-top: 10px;
            color: var(--white);
          }
        }
      }
    }
  }

  .footer {
    padding: 4rem 0 6rem 0;
    background: #2ec56a;

    .top-footer {
      p {
        font-size: 0.875rem;
        color: var(--white);
      }
    }

    .bottom-footer {
      .footer-navigation {
        flex: 0 0 auto;
        width: 100%;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @media (min-width: 992px) {
          justify-content: space-between;
        }

        .footer-copyright {
          flex: 0 0 auto;
          width: 100%;
          color: var(--white);
          p {
            color: var(--white);
          }

          @media (min-width: 992px) {
            width: auto;
          }
        }

        .footer-navigation-wrapper {
          flex: 0 0 auto;
          width: 100%;

          @media (min-width: 992px) {
            width: auto;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
              margin-bottom: 1rem;
              &:nth-child(1) {
                &::after {
                  content: "|";
                  margin: 0 1rem;
                  color: var(--white);
                }
              }

              a {
                text-decoration: none;
                color: var(--white);
              }
            }
          }
        }
      }
    }
  }

  .policy-wrapper {
    .policy-title {
      padding: 2rem 0;

      h1 {
        font-size: calc(1.375rem + 1.5vw);

        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }

      flex: 0 0 auto;
      width: 100%;
      border-bottom: 1px solid gray;
      margin-bottom: 2rem;
    }

    .policy-paragraph {
      padding-bottom: 4rem;

      p {
        margin-bottom: 0.5rem;
      }

      h2 {
        font-size: calc(1.325rem + 0.9vw);
        margin: 1rem 0;

        @media (min-width: 1200px) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: calc(1.3rem + 0.6vw);
        margin-bottom: 1rem;

        @media (min-width: 1200px) {
          font-size: 1.75rem;
        }
      }

      h3 {
        font-size: calc(1.275rem + 0.3vw);

        @media (min-width: 1200px) {
          font-size: 1.5rem;
        }
      }

      ul {
        list-style-type: disc;
        margin-bottom: 0.5rem;
      }
    }
  }
}
